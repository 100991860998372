/* JSK JM */
body, html{
    width: 100% !important;
    height: 100% !important;
    margin: 0;
    padding: 0;

    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.image-homepage-banner-wrapper {
    position: fixed;
    width: 41.66666667%;
    height: 100%;
    padding: 20px;
    background: url(../images/homepage-doctors-splash.jpg);
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

[class|="confetti"] {
    position: absolute;
    z-index: 9999;
}

.low-padding{
    padding:5px 10px;
}

.top-padding-25{
    padding-top:25px;
}
.top-padding-50{
    padding-top:50px;
}
.top-padding-81{
    padding-top:81px;
}
.top-padding-144{
    padding-top:144px;
}

.top-margin-0{
    margin-top:0;
}

.top-margin-5{
    margin-top:5px;
}
.top-margin-9{
    margin-top:9px;
}
.top-margin-25{
    margin-top:25px;
}
.top-margin-36{
    margin-top:36px;
}
.top-margin-50{
    margin-top:50px;
}
.top-margin-81{
    margin-top:81px;
}
.top-margin-144{
    margin-top:144px;
}

.left-margin-5{
    margin-left: 5px;
}

.inline-left{
    margin-top:0px;
    margin-right: 10px;
    float:left;
}

.fixed-action-btn{
    right: 10px;
    bottom: 14px;
}

.icon-btn .material-icons{
    margin-top: 10px; font-size: 18px;
}

.alert-badge{
    float:left;
    text-align: center;
    margin-top: 13px;
    margin-right: 5px;
    height: 9px;
    width: 9px;
    border-radius: 100%;
}

.main-menu-holder{
    top:30px; right:11px; height: auto; bottom:unset;
}

.escape-btn-holder{
    position: fixed;
    z-index: 99;
    bottom: 11px;
    left: 10px;
}

.cme-btn-holder{
    position: fixed;
    z-index: 99;
    bottom: 4px;
    right: 10px;
}

.cme-credit-counter{
    position: fixed;
    z-index: 108;
    bottom: 45px;
    right: 10px;
}

.hover-holder{
    position: relative;
    cursor: pointer;
}

.hover-img{
    -webkit-filter: brightness(100%);
    -webkit-transition: all 250ms ease;
    -moz-transition: all 250ms ease;
    -o-transition: all 250ms ease;
    -ms-transition: all 250ms ease;
    transition: all 250ms ease;
}

.hover-img:hover{
    -webkit-filter: brightness(50%);
}

.hover-text{
    -webkit-transition: all 250ms ease;
    -moz-transition: all 250ms ease;
    -o-transition: all 250ms ease;
    -ms-transition: all 250ms ease;
    transition: all 250ms ease;
    display:flex;justify-content:center;align-items:center;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.hover-holder:hover .hover-text{
    display:flex;justify-content:center;align-items:center;
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.hover-holder:hover img{
    -webkit-filter: brightness(50%);
}

.hover-text div{
    z-index: 99;
    width: 100%;
    text-align: center;
    margin: auto;
    color: white;
    font-family: Roboto, sans-serif;
    font-weight: 500;
}

.image-fit{
    width: 144px;
}

.image-fit-small{
    height: 36px;
}

.image-fit-brands{
    width: 144px;
}

.image-logo{
    width: 81px;
    margin-right: 10px;
}

.image-fit-small-inline {
    height: 24px; margin-right: 1px; margin-top:-2px; float:left;
}

.watermark-image-logo{
    position: fixed;
    width: 254px;
    left:-108px;
    bottom:-108px;
    opacity: 0.1;
    filter: blur(2px);
    z-index: -1;
    pointer-events: none;
}

.blur-me-auto{
    filter: blur(0);
    -webkit-transition: background 1s ease-out;
    -moz-transition: background 1s ease-out;
    -o-transition: background 1s ease-out;
    transition: background 1s ease-out;
}

.blur-me-auto.blur-active{
    filter: blur(4px);
}

.fixed-action-btn.direction-left ul li, .fixed-action-btn.direction-right ul li {
    display: inline-block;
    margin: 7.5px 9px 0 0;
}

.signal-title{
    display:block;
    font-size: 27px;
    font-weight: 900;
    font-family: Roboto, sans-serif;
    padding-top:29px;
    margin-bottom:10px;
}

.toggle-signal-btn{
    position: fixed;
    z-index: 999;
    top:47px;
    right: 18px;

    border-radius: 50px;
}

.modal .modal-content{
    padding: 14px;
}

.big-modal{
    top: 0 !important;
    bottom:0;
    width: 100%;
    max-width: 630px;
    height: 100% !important;
    max-height: 720px !important;
}

.fullscreen-modal{
    margin: 0 !important;
    top: 0 !important;
    bottom:0;
    width: 100% !important;
    height: 100% !important;
    max-height: 100% !important;
}

.fullscreen-modal #whereby_iframe_holder{
    padding: 0;
}

.login-modal{
    max-width: 482px; width:100%; min-height: 572px;
}

.mini-modal{
    max-width: 360px; width:100%; max-height: 540px; height:auto;
}

.fine-print{
    font-size:.9em;
}

.sub-fine-print{
    font-size: 0.81em;
}

.super-fine-print{
    font-size: 0.72em;
}

.bold-text{
    font-weight: 700;
}

.ui-action-btn{
    cursor: pointer;
}

.disabled-action-btn{
    opacity: 0.36;
}

.cmefy-card-effects{
    border-radius: 5px;
    padding: 9px 18px;
}

.cmefy-card-effects .card-chip{
    position: absolute;
    top:10px;
    right: 10px;
}

.iso_nudge_btn{
    margin-bottom: 10px;
    margin-right: 5px;
    min-width: 108px;
    padding: 5px 7px;
    color: white;
    font-weight: 400;
    font-size: 1.08em;
    border-radius: 5px;
    /* display: table; */
}
.rounded-btn{
    border-radius: 25px;
    margin-bottom: 10px;
}

.soft-rounded-btn{
    border-radius: 9px;
    margin-bottom: 10px;
}

.small-rounded-corners{
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 10px;
}

.card-title-alt{
    position: relative;
    top:-21px;
    padding: 5px;
}

.cmefy-header-bar{
    position: fixed;
    top:0;
    width: inherit;
    max-width: 1260px;
    height:89px;
    border-radius: 0px 0px 14px 14px;
    z-index: 99;
    -webkit-transition: all 0.18s ease-in-out;
    -moz-transition: all 0.18s ease-in-out;
    -o-transition: all 0.18s ease-in-out;
    transition: all 0.18s ease-in-out;
}

.cmefy-header-bar.shadow{
    background: white;
    -webkit-box-shadow: 0px 0px 25px 0px rgba(153,153,153,1);
    -moz-box-shadow: 0px 0px 25px 0px rgba(153,153,153,1);
    box-shadow: 0px 0px 25px 0px rgba(153,153,153,1);
}

.cmefy-item-action-btn{
    padding: 0px 5px;
}

.cmefy-item-table-action-link{
    cursor: pointer;
    margin-left: 5px;
}

.copy-contents-btn{
    cursor: pointer;
    font-weight: 400;
    letter-spacing: -0.05em;
    padding: 5px;
    border-radius: 3px;
}

.tight-btn{
    padding-left: 5px;
    padding-right: 5px;
}

.typeform-popup .typeform-iframe-wrapper {
    min-width: 300px !important;
    width: calc(100% - 40px) !important;
}

a.typeform-close:not([href]):not([tabindex]), a.typeform-close:not([href]):not([tabindex]):focus, a.typeform-close:not([href]):not([tabindex]):hover {
    color: white !important;
    text-decoration: none;
}

.cmefied-table-styles td{
    font-weight: 200;
}
.cmefied-table-styles .titled{
    text-transform: none;
    letter-spacing: -0.05em;
    font-weight: 500;
}

.impact-badge{
    width:25px;
    margin-right:10px;
    float:left;
    margin-top: -1px;
}

.impact-stats-holder{
    display: inline-block;
    padding: 7px 7px;
    margin: 0 0 7px -5px;
    border-radius: 5px;
    font-weight: 200;
}

.profile-url-field{
    font-size: 0.81em !important;
    margin-bottom: 0 !important;
}

.profile-url-field-mgr{
    margin-bottom: 0 !important;
    padding: 3px;
    border-radius: 5px;
    display: inline-block;
    width: auto;
    min-width: 225px;
    border: none;
}

.cursor-pointer{
    cursor: copy !important;
}

.code-text{
    font-family: monospace;
    font-size: 11px;
}

.collapsible-image{
    height: 21px;
    width: auto;
    margin-right: 10px;
}

.wrap {
    white-space: pre-wrap;      /* CSS3 */
    white-space: -moz-pre-wrap; /* Firefox */
    white-space: -pre-wrap;     /* Opera <7 */
    white-space: -o-pre-wrap;   /* Opera 7 */
    word-wrap: break-word;      /* IE */
}

.stripe-connect {
    background: #635bff;
    display: inline-block;
    height: 38px;
    text-decoration: none;
    width: 180px;

    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;

    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    -webkit-font-smoothing: antialiased;
}

.stripe-connect span {
    color: #ffffff;
    display: block;
    font-family: sohne-var, "Helvetica Neue", Arial, sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 14px;
    padding: 11px 0px 0px 24px;
    position: relative;
    text-align: left;
}

.stripe-connect:hover {
    background: #7a73ff;
}

.stripe-connect.slate {
    background: #0a2540;
}

.stripe-connect.slate:hover {
    background: #425466;
}

.stripe-connect.white {
    background: #ffffff;
}

.stripe-connect.white span {
    color: #0a2540;
}

.stripe-connect.white:hover {
    background: #f6f9fc;
}

.stripe-connect span::after {
    background-repeat: no-repeat;
    background-size: 49.58px;
    content: "";
    height: 20px;
    left: 62%;
    position: absolute;
    top: 28.95%;
    width: 49.58px;
}

/* Logos */
.stripe-connect span::after {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 23.0.4, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 468 222.5' style='enable-background:new 0 0 468 222.5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%23FFFFFF;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M414,113.4c0-25.6-12.4-45.8-36.1-45.8c-23.8,0-38.2,20.2-38.2,45.6c0,30.1,17,45.3,41.4,45.3 c11.9,0,20.9-2.7,27.7-6.5v-20c-6.8,3.4-14.6,5.5-24.5,5.5c-9.7,0-18.3-3.4-19.4-15.2h48.9C413.8,121,414,115.8,414,113.4z M364.6,103.9c0-11.3,6.9-16,13.2-16c6.1,0,12.6,4.7,12.6,16H364.6z'/%3E%3Cpath class='st0' d='M301.1,67.6c-9.8,0-16.1,4.6-19.6,7.8l-1.3-6.2h-22v116.6l25-5.3l0.1-28.3c3.6,2.6,8.9,6.3,17.7,6.3 c17.9,0,34.2-14.4,34.2-46.1C335.1,83.4,318.6,67.6,301.1,67.6z M295.1,136.5c-5.9,0-9.4-2.1-11.8-4.7l-0.1-37.1 c2.6-2.9,6.2-4.9,11.9-4.9c9.1,0,15.4,10.2,15.4,23.3C310.5,126.5,304.3,136.5,295.1,136.5z'/%3E%3Cpolygon class='st0' points='223.8,61.7 248.9,56.3 248.9,36 223.8,41.3 '/%3E%3Crect x='223.8' y='69.3' class='st0' width='25.1' height='87.5'/%3E%3Cpath class='st0' d='M196.9,76.7l-1.6-7.4h-21.6v87.5h25V97.5c5.9-7.7,15.9-6.3,19-5.2v-23C214.5,68.1,202.8,65.9,196.9,76.7z'/%3E%3Cpath class='st0' d='M146.9,47.6l-24.4,5.2l-0.1,80.1c0,14.8,11.1,25.7,25.9,25.7c8.2,0,14.2-1.5,17.5-3.3V135 c-3.2,1.3-19,5.9-19-8.9V90.6h19V69.3h-19L146.9,47.6z'/%3E%3Cpath class='st0' d='M79.3,94.7c0-3.9,3.2-5.4,8.5-5.4c7.6,0,17.2,2.3,24.8,6.4V72.2c-8.3-3.3-16.5-4.6-24.8-4.6 C67.5,67.6,54,78.2,54,95.9c0,27.6,38,23.2,38,35.1c0,4.6-4,6.1-9.6,6.1c-8.3,0-18.9-3.4-27.3-8v23.8c9.3,4,18.7,5.7,27.3,5.7 c20.8,0,35.1-10.3,35.1-28.2C117.4,100.6,79.3,105.9,79.3,94.7z'/%3E%3C/g%3E%3C/svg%3E");
}

.stripe-connect.white span::after {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 468 222.5' style='enable-background:new 0 0 468 222.5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%230A2540;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M414,113.4c0-25.6-12.4-45.8-36.1-45.8c-23.8,0-38.2,20.2-38.2,45.6c0,30.1,17,45.3,41.4,45.3 c11.9,0,20.9-2.7,27.7-6.5v-20c-6.8,3.4-14.6,5.5-24.5,5.5c-9.7,0-18.3-3.4-19.4-15.2h48.9C413.8,121,414,115.8,414,113.4z M364.6,103.9c0-11.3,6.9-16,13.2-16c6.1,0,12.6,4.7,12.6,16H364.6z'/%3E%3Cpath class='st0' d='M301.1,67.6c-9.8,0-16.1,4.6-19.6,7.8l-1.3-6.2h-22v116.6l25-5.3l0.1-28.3c3.6,2.6,8.9,6.3,17.7,6.3 c17.9,0,34.2-14.4,34.2-46.1C335.1,83.4,318.6,67.6,301.1,67.6z M295.1,136.5c-5.9,0-9.4-2.1-11.8-4.7l-0.1-37.1 c2.6-2.9,6.2-4.9,11.9-4.9c9.1,0,15.4,10.2,15.4,23.3C310.5,126.5,304.3,136.5,295.1,136.5z'/%3E%3Cpolygon class='st0' points='223.8,61.7 248.9,56.3 248.9,36 223.8,41.3 '/%3E%3Crect x='223.8' y='69.3' class='st0' width='25.1' height='87.5'/%3E%3Cpath class='st0' d='M196.9,76.7l-1.6-7.4h-21.6v87.5h25V97.5c5.9-7.7,15.9-6.3,19-5.2v-23C214.5,68.1,202.8,65.9,196.9,76.7z'/%3E%3Cpath class='st0' d='M146.9,47.6l-24.4,5.2l-0.1,80.1c0,14.8,11.1,25.7,25.9,25.7c8.2,0,14.2-1.5,17.5-3.3V135 c-3.2,1.3-19,5.9-19-8.9V90.6h19V69.3h-19L146.9,47.6z'/%3E%3Cpath class='st0' d='M79.3,94.7c0-3.9,3.2-5.4,8.5-5.4c7.6,0,17.2,2.3,24.8,6.4V72.2c-8.3-3.3-16.5-4.6-24.8-4.6 C67.5,67.6,54,78.2,54,95.9c0,27.6,38,23.2,38,35.1c0,4.6-4,6.1-9.6,6.1c-8.3,0-18.9-3.4-27.3-8v23.8c9.3,4,18.7,5.7,27.3,5.7 c20.8,0,35.1-10.3,35.1-28.2C117.4,100.6,79.3,105.9,79.3,94.7z'/%3E%3C/g%3E%3C/svg%3E");
}

/* Please remove from your implementation. Used to display the white button on the white background */
.stripe-connect.white {
    left: 1px;
    position: relative;
    top: 1px;
}

/* Paid View */

.iti__flag {background-image: url("../images/flags/flags.png");}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .iti__flag {background-image: url("../images/flags/flags@2x.png");}
}

.iti-container {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
}

#pay_phonenum, #verification_code, .iti-btn {
    padding-top: 6px;
    padding-bottom: 6px;
    border: 1px solid #aaa !important;
    border-radius: 5px;
}

.iti-btn {
    color: #ffffff;
    background-color: #428BCA !important;
    border-color: #357EBD !important;
    font-size: 14px;
    outline: none;
    cursor: pointer;
    padding-left: 12px;
    padding-right: 12px;
}

.iti-btn:focus, .iti-btn:hover {
    background-color: #3276B1;
    border-color: #285E8E;
}

.iti-btn:active {
    box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
}

.alert {
    padding: 15px;
    margin-top: 10px;
    border: 1px solid transparent;
    border-radius: 4px;
}

.alert-info {
    border-color: #bce8f1;
    color: #31708f;
    background-color: #d9edf7;
}

.alert-error {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}

/* Instant Rounds */
.instant-round-footer{
    position: absolute;
    bottom: 0px;
    height: 45px;
    left:0px; right:0px;
    padding-bottom: 0;
    margin-bottom: 0;
}

.instant-round-holder{
    position: fixed;
    top: 45px;
    left:0px;
    right:0px;
    bottom:45px;
}

.instant-round-title{
    float:left;
    font-size: 0.81em;
    max-width: calc(100%-180px) !important;
    margin-top: 7px !important;
}

.instant-round-title img{
    max-height: 32px;
    margin-top: -7px;
    margin-left: -2px;
    margin-right: 5px;
}

.link-copier{
    font-size: 0.72em;
}

/* Link table view */
.smart-table{
    height: calc(100%-400px);
}

.cmefy-master-announcement-bar{
    display: none;
    position: fixed !important;
    z-index: 1450;
    bottom: 14px;
    width: auto;
    min-width: 270px;
    max-width: 95%;
    min-height: 25px;
    left: 50%;
    transform: translate(-50%, 0%);
    margin: 0 auto;
    padding: 9px;
    border-radius: 7px;
}

/* Animates */
.animation-bounce{
    animation-name: bounce;
    animation-duration: 1s;
}

@keyframes bounce{
    0%{
        transform: translateY(0);
    }
    30%{
        transform: translateY(-70px);
    }
    50%{
        transform: translateY(5px);
    }
    70%{
        transform: translateY(-25px);
    }
    80%{
        transform: translateY(2px);
    }
    90%{
        transform: translateY(-15px);
    }
    100%{
        transform: translateY(0);
    }
}

.flip-horizontal-top {
	-webkit-animation: flip-horizontal-top 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
	        animation: flip-horizontal-top 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-1-22 16:14:22
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-horizontal-top
 * ----------------------------------------
 */
 @-webkit-keyframes flip-horizontal-top {
    0% {
      -webkit-transform: rotateX(0);
              transform: rotateX(0);
    }
    100% {
      -webkit-transform: rotateX(180deg);
              transform: rotateX(180deg);
    }
  }
  @keyframes flip-horizontal-top {
    0% {
      -webkit-transform: rotateX(0);
              transform: rotateX(0);
    }
    100% {
      -webkit-transform: rotateX(180deg);
              transform: rotateX(180deg);
    }
  }
  

  .wobble-hor-bottom {
	-webkit-animation: wobble-hor-bottom 0.8s both;
	        animation: wobble-hor-bottom 0.8s both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-1-22 16:17:30
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation wobble-hor-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes wobble-hor-bottom {
    0%,
    100% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
    }
    15% {
      -webkit-transform: translateX(-30px) rotate(-6deg);
              transform: translateX(-30px) rotate(-6deg);
    }
    30% {
      -webkit-transform: translateX(15px) rotate(6deg);
              transform: translateX(15px) rotate(6deg);
    }
    45% {
      -webkit-transform: translateX(-15px) rotate(-3.6deg);
              transform: translateX(-15px) rotate(-3.6deg);
    }
    60% {
      -webkit-transform: translateX(9px) rotate(2.4deg);
              transform: translateX(9px) rotate(2.4deg);
    }
    75% {
      -webkit-transform: translateX(-6px) rotate(-1.2deg);
              transform: translateX(-6px) rotate(-1.2deg);
    }
  }
  @keyframes wobble-hor-bottom {
    0%,
    100% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
    }
    15% {
      -webkit-transform: translateX(-30px) rotate(-6deg);
              transform: translateX(-30px) rotate(-6deg);
    }
    30% {
      -webkit-transform: translateX(15px) rotate(6deg);
              transform: translateX(15px) rotate(6deg);
    }
    45% {
      -webkit-transform: translateX(-15px) rotate(-3.6deg);
              transform: translateX(-15px) rotate(-3.6deg);
    }
    60% {
      -webkit-transform: translateX(9px) rotate(2.4deg);
              transform: translateX(9px) rotate(2.4deg);
    }
    75% {
      -webkit-transform: translateX(-6px) rotate(-1.2deg);
              transform: translateX(-6px) rotate(-1.2deg);
    }
  }
  
.wiggle-me-simple{
    animation: wiggle-simple 2.5s infinite;
}

@keyframes wiggle-simple {
    0% { transform: rotate(0deg); }
   80% { transform: rotate(0deg); }
   85% { transform: rotate(9deg); }
   95% { transform: rotate(-9deg); }
  100% { transform: rotate(0deg); }
}